import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Statistics = () => import('@/entities/statistics/statistics.vue');
const StatisticsDetails = () => import('@/entities/statistics/statistics-details.vue');

const Assessment = () => import('@/entities/assessment/assessment.vue');
const AssessmentUpdate = () => import('@/entities/assessment/assessment-update.vue');
const AssessmentDetails = () => import('@/entities/assessment/assessment-details.vue');

const FirstTraining = () => import('@/entities/first-training/first-training.vue');
const FirstTrainingUpdate = () => import('@/entities/first-training/first-training-update.vue');
const FirstTrainingDetails = () => import('@/entities/first-training/first-training-details.vue');

const SecondTraining = () => import('@/entities/second-training/second-training.vue');
const SecondTrainingUpdate = () => import('@/entities/second-training/second-training-update.vue');
const SecondTrainingDetails = () => import('@/entities/second-training/second-training-details.vue');

const ThirdTraining = () => import('@/entities/third-training/third-training.vue');
const ThirdTrainingUpdate = () => import('@/entities/third-training/third-training-update.vue');
const ThirdTrainingDetails = () => import('@/entities/third-training/third-training-details.vue');

const FourthTraining = () => import('@/entities/fourth-training/fourth-training.vue');
const FourthTrainingUpdate = () => import('@/entities/fourth-training/fourth-training-update.vue');
const FourthTrainingDetails = () => import('@/entities/fourth-training/fourth-training-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'statistics',
      name: 'Statistics',
      component: Statistics,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'statistics/:userId/view',
      name: 'StatisticsView',
      component: StatisticsDetails,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'assessment',
      name: 'Assessment',
      component: Assessment,
      meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
    },
    {
      path: 'assessment/:assessmentId/view',
      name: 'AssessmentView',
      component: AssessmentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'first-training',
      name: 'FirstTraining',
      component: FirstTraining,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'first-training/:firstTrainingId/view',
      name: 'FirstTrainingView',
      component: FirstTrainingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'second-training',
      name: 'SecondTraining',
      component: SecondTraining,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'second-training/:secondTrainingId/view',
      name: 'SecondTrainingView',
      component: SecondTrainingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'third-training',
      name: 'ThirdTraining',
      component: ThirdTraining,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'third-training/:thirdTrainingId/view',
      name: 'ThirdTrainingView',
      component: ThirdTrainingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'fourth-training',
      name: 'FourthTraining',
      component: FourthTraining,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'fourth-training/:fourthTrainingId/view',
      name: 'FourthTrainingView',
      component: FourthTrainingDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};

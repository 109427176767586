var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "bg-primary",
      staticStyle: { "background-color": "#2e9878 !important" },
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
        [
          _c("span", { staticClass: "logo-img" }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-version" }, [_vm._v("v0.0.1")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "header-tabs" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _vm.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: "/statistics", exact: "" } },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("순응도")]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item",
                    { attrs: { to: "/admin/user-management", exact: "" } },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", { attrs: { icon: "users" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("사용자 관리")]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/account") },
                  attrs: {
                    right: "",
                    href: "javascript:void(0);",
                    id: "account-menu",
                    "active-class": "active",
                    "data-cy": "accountMenu",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "navbar-dropdown-menu",
                      attrs: { slot: "button-content" },
                      slot: "button-content",
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "user" } }),
                      _vm._v(" "),
                      _c("span", { staticClass: "no-bold" }, [
                        _vm._v(" 계정 "),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "logout",
                            id: "logout",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-out-alt" },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("로그아웃")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "login",
                            id: "login",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openLogin()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-in-alt" },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("로그인")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
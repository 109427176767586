import axios from 'axios';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import TranslationService from '@/locale/translation.service';

export default class AccountService {
  constructor(private store: Store<any>, private translationService: TranslationService, private router: VueRouter) {
    this.init();
  }

  public init(): void {
    this.retrieveProfiles();
  }

  public retrieveProfiles(): void {}

  public async getUser(): Promise<string> {
    const response = await axios.get<any>('api/account');
    return response.data;
  }

  public async retrieveAccount(): Promise<boolean> {
    try {
      const response = await axios.get<any>('api/account');
      this.store.commit('authenticate');
      const account = response.data;
      if (account) {
        this.store.commit('authenticated', account);
        if (sessionStorage.getItem('requested-url')) {
          await this.router.replace(sessionStorage.getItem('requested-url'));
          sessionStorage.removeItem('requested-url');
        }
      } else {
        this.store.commit('logout');
        if (this.router.currentRoute.path !== '/') {
          await this.router.push('/');
        }
        sessionStorage.removeItem('requested-url');
      }
      this.translationService.refreshTranslation(this.store.getters.currentLanguage);
      return true;
    } catch (error) {
      this.store.commit('logout');
      return false;
    }
  }

  public hasAnyAuthorityAndCheckAuth(authorities: any): boolean {
    if (typeof authorities === 'string') {
      authorities = [authorities];
    }

    if (!this.authenticated || !this.userAuthorities) {
      const token = localStorage.getItem('ixt-authenticationToken') || sessionStorage.getItem('ixt-authenticationToken');
      if (!this.store.getters.account && !this.store.getters.logon && token) {
        this.retrieveAccount();
      }
      return false;
    }

    return this.checkAuthorities(authorities);
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public get userAuthorities(): any {
    return this.store.getters.account?.authorities;
  }

  private checkAuthorities(authorities: any): boolean {
    if (this.userAuthorities) {
      for (const authority of authorities) {
        if (this.userAuthorities.includes(authority)) {
          return true;
        }
      }
    }
    return false;
  }
}

import { Authority } from '@/shared/security/authority';

const IxtUserManagementComponent = () => import('@/admin/user-management/user-management.vue');
const IxtUserManagementViewComponent = () => import('@/admin/user-management/user-management-view.vue');
const IxtUserManagementNewComponent = () => import('@/admin/user-management/user-management-new.vue');
const IxtUserManagementEditComponent = () => import('@/admin/user-management/user-management-edit.vue');
const IxtDocsComponent = () => import('@/admin/docs/docs.vue');
const IxtConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const IxtHealthComponent = () => import('@/admin/health/health.vue');
const IxtLogsComponent = () => import('@/admin/logs/logs.vue');
const IxtMetricsComponent = () => import('@/admin/metrics/metrics.vue');

export default [
  {
    path: '/admin/user-management',
    name: 'IxtUser',
    component: IxtUserManagementComponent,
    meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
  },
  {
    path: '/admin/user-management/new',
    name: 'IxtUserCreate',
    component: IxtUserManagementNewComponent,
    meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
  },
  {
    path: '/admin/user-management/:userId/edit',
    name: 'IxtUserEdit',
    component: IxtUserManagementEditComponent,
    meta: { authorities: [Authority.ADMIN, Authority.CRC] },
  },
  {
    path: '/admin/user-management/:userId/view',
    name: 'IxtUserView',
    component: IxtUserManagementViewComponent,
    meta: { authorities: [Authority.ADMIN, Authority.CRC, Authority.CRO] },
  },
  {
    path: '/admin/docs',
    name: 'IxtDocsComponent',
    component: IxtDocsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/health',
    name: 'IxtHealthComponent',
    component: IxtHealthComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/logs',
    name: 'IxtLogsComponent',
    component: IxtLogsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/metrics',
    name: 'IxtMetricsComponent',
    component: IxtMetricsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/configuration',
    name: 'IxtConfigurationComponent',
    component: IxtConfigurationComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
];

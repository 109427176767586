import { Stats } from 'fs';
import { Module } from 'vuex';

export const translationStore: Module<any, any> = {
  state: {
    currentLanguage: localStorage.getItem('currentLanguage') || 'en',
    currentExoEye: localStorage.getItem('currentExoEye') || 'both',
    currentGroup: localStorage.getItem('currentGroup') || 'experimental',
    currentIpd: localStorage.getItem('currentIpd') || '1',
    currentCenterName: localStorage.getItem('currentCenterName') || '01',
    languages: {
      en: { name: 'English' },
      // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
    },
    exoEyes: {
      left: { name: '좌안' },
      right: { name: '우안' },
      both: { name: '양안' },
    },
    groups: {
      experimental: { name: '시험군' },
      control: { name: '대조군' },
    },
    ipds: {
      1: { name: '1' },
      2: { name: '2' },
      3: { name: '3' },
    },
    centerNames: {
      '01': { name: '01: 서울아산병원' },
      '02': { name: '02: 건국대학교병원' },
      '03': { name: '03: 노원을지대학교병원' },
      '04': { name: '04: 인제대학교 일산백병원' },
      '05': { name: '05: 의정부을지대학교병원' },
      '06': { name: '06: 한길안과병원' },
      '999': { name: '999: 기타' },
    },
  },
  getters: {
    currentLanguage: state => state.currentLanguage,
    languages: state => state.languages,
    currentExoEye: state => state.currentExoEye,
    exoEyes: state => state.exoEyes,
    currentGroup: state => state.currentGroup,
    groups: state => state.groups,
    currentIpd: state => state.currentIpd,
    ipds: state => state.ipds,
    currentCenterName: state => state.currentCenterName,
    centerNames: state => state.centerNames,
  },
  mutations: {
    currentLanguage(state, newLanguage) {
      state.currentLanguage = newLanguage;
      localStorage.setItem('currentLanguage', newLanguage);
    },
    currentExoEye(state, newExoEye) {
      state.currentExoEye = newExoEye;
      localStorage.setItem('currentExoEye', newExoEye);
    },
    currentGroup(state, newGroup) {
      state.currentGroup = newGroup;
      localStorage.setItem('currentGroup', newGroup);
    },
    currentIpd(state, newIpd) {
      state.currentIpd = newIpd;
      localStorage.setItem('currentIpd', newIpd);
    },
    currentCenterName(state, newCenterName) {
      state.currentCenterName = newCenterName;
      localStorage.setItem('currentCenterName', newCenterName);
    },
  },
};
